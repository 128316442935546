import React, { useContext, useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { Dialog, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormField from 'caterers/components/FormField';
import { Mouseflow } from 'lib/mouseflow';
import Yup from 'lib/yup';
import { FeedbackMessageContext } from 'shared/components/FeedbackContext';
import useAuthentication from 'shared/hooks/useAuthentication';

const FORM_ID = 'qualification-login-form';

export default function LoginModal({ open, setOpen }) {
  const { t } = useTranslation('qualification');
  const [isLoading, setLoading] = useState(false);
  const { setFeedbackMessage } = useContext(FeedbackMessageContext);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { login } = useAuthentication();

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(values, formikBag) {
    try {
      setLoading(true);
      Mouseflow.notify.formSubmitAttempt(FORM_ID);
      await login(values);
      setOpen(false);
      Mouseflow.notify.formSubmitSuccess(FORM_ID);
    } catch (err) {
      Mouseflow.notify.formSubmitFailure(FORM_ID);
      formikBag.resetForm();
      setFeedbackMessage(t('request.onSubmitErrors.logInError'), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} anchor="bottom" disableAutoFocus>
      <Box position="absolute" right={0} top={0} m={1}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box width={mobile ? 280 : 349} p={mobile ? 1 : 3} pb={4} mx="auto">
        <Typography
          variant="subtitle1"
          component="h1"
          textAlign="center"
          mb={3}
        >
          {t('request.signIn.register')}
        </Typography>
        <Formik
          id={FORM_ID}
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required(t('request.formErrors.emailRequired'))
              .email(t('request.formErrors.correctFormatEmail')),
          })}
          onSubmit={onSubmit}
        >
          <Form>
            <Field
              label={t('request.signIn.email')}
              placeholder={t('request.signIn.email')}
              name="email"
              id="sign-in-email"
              component={StyledFormField}
            />
            <Field
              label={t('request.signIn.password')}
              placeholder={t('request.signIn.password')}
              name="password"
              type="password"
              component={StyledFormField}
            />
            <Button
              block
              disabled={isLoading}
              type="submit"
              role="button"
              aria-label="sign in"
              id="login-modal-submit"
            >
              {t('request.signIn.register')}
            </Button>
          </Form>
        </Formik>
      </Box>
    </Dialog>
  );
}

const StyledFormField = styled(FormField)`
  input {
    padding: 27px 12px 10px;
  }
`;
