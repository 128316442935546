import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

function Locale() {
  const router = useRouter();

  return (
    <nav>
      <h2 className="sr-only">Language switcher</h2>
      <Link href={router.asPath} passHref locale="de">
        <ToggleButton selected={router.locale === 'de'}>DE</ToggleButton>
      </Link>
      <Link href={router.asPath} passHref locale="en">
        <ToggleButton selected={router.locale === 'en'}>EN</ToggleButton>
      </Link>
    </nav>
  );
}

const ToggleButton = styled.a`
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(1, 1)};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${(props) => props.theme.palette.grey[600]};
  margin: 0 4px;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[200]};
    color: black;
  }

  ${(props) =>
    props.selected &&
    css`
      pointer-events: none;
      color: ${(props) => props.theme.palette.common.white};
      cursor: default;
    `};
`;

export default Locale;
