import React, { useContext, useEffect } from 'react';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FeedbackMessageContext } from 'shared/components/FeedbackContext';

const StyledNotificationsNoneIcon = styled(NotificationsNoneIcon)`
  font-size: ${(props) => props.theme.spacing(2.5)}px;
  opacity: 0.9;
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const StyledWarningIcon = styled(WarningIcon)`
  font-size: ${(props) => props.theme.spacing(2.5)}px;
  opacity: 0.9;
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: ${(props) => props.theme.spacing(2.5)}px;
  opacity: 0.9;
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const variantIcon = {
  success: StyledNotificationsNoneIcon,
  warning: StyledWarningIcon,
  error: StyledErrorIcon,
};

export default function FeedbackMessage({
  action,
  autoHideDuration,
  message,
  variant,
}) {
  const { resetMessage } = useContext(FeedbackMessageContext);
  const [open, setOpen] = React.useState(false);
  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    resetMessage();
    setOpen(false);
  };

  const actions = [
    // eslint-disable-next-line react/jsx-key
    <IconButton color="inherit" p={0.5} onClick={handleClose}>
      <CloseIcon />
    </IconButton>,
  ];

  if (action) {
    actions.unshift(action);
  }

  useEffect(() => setOpen(!!message), [message]);

  const SnackbarComponent = (() => {
    switch (variant) {
      case 'warning':
        return WarningSnackbarContent;
      case 'error':
        return ErrorSnackbarContent;
      default:
        return SuccessSnackbarContent;
    }
  })();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarComponent
        aria-describedby="client-snackbar"
        p={0}
        message={
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Icon />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
}

const SuccessSnackbarContent = styled(SnackbarContent)`
  .MuiSnackbarContent-message {
    padding-top: ${(props) => props.theme.spacing(1, 2)}px;
    padding-bottom: 0;
  }
  background-color: ${({ theme }) => theme.palette.secondary['400']};

  & a {
    color: ${(props) => props.theme.palette.common.white};
    text-decoration: 'underline';
  }
`;

const ErrorSnackbarContent = styled(SnackbarContent)`
  .MuiSnackbarContent-message {
    padding-top: ${(props) => props.theme.spacing(1, 2)}px;
    padding-bottom: 0;
  }
  background-color: ${(props) => props.theme.palette.error.main};
  min-width: auto;
  & a {
    color: ${(props) => props.theme.palette.common.white};
    text-decoration: underline;
  }
`;

const WarningSnackbarContent = styled(SnackbarContent)`
  .MuiSnackbarContent-message {
    padding-top: ${(props) => props.theme.spacing(1, 2)}px;
    padding-bottom: 0;
  }
  background-color: ${(props) => props.theme.palette.warning.dark};
  min-width: auto;
  & a {
    color: ${(props) => props.theme.palette.common.white};
    text-decoration: 'underline';
  }
`;
FeedbackMessage.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoHideDuration: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
};

FeedbackMessage.defaultProps = {
  autoHideDuration: 6000,
  variant: 'success',
};
