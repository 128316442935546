import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import toPairs from 'lodash/toPairs';
import { NextRouter } from 'next/router';
import { parse, stringify } from 'qs';

import { IFaqItem } from 'lib/types/generated/contentful';
import {
  CateringCategoryValue,
  isValidCateringCategory,
} from 'qualification/schema/questions/cateringCategoriesQuestion';
import {
  CityValue,
  isValidCity,
} from 'qualification/schema/questions/cityQuestion';

export const buildFaqMarkup = (faqs: IFaqItem[]) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.fields.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: documentToPlainTextString(faq.fields.answer),
      },
    })),
  };
};

interface BuildFunnelUrlArgs {
  city?: string;
  cateringCategories?: CateringCategoryValue[] | null;
  selectedCatererId?: string;
  router: NextRouter;
}

export const buildFunnelUrl = ({
  city,
  cateringCategories,
  selectedCatererId,
  router,
}: BuildFunnelUrlArgs) => {
  const parsedParameters: {
    city?: CityValue;
    catering_categories?: CateringCategoryValue[];
    selectedCatererId?: string;
  } = {};

  if (city) {
    parsedParameters.city = isValidCity(city.toLowerCase())
      ? (city.toLowerCase() as CityValue)
      : 'other';
  }

  if (selectedCatererId) {
    parsedParameters.selectedCatererId = selectedCatererId;
  }

  if (Array.isArray(cateringCategories)) {
    const validCateringCategories = cateringCategories.filter(
      isValidCateringCategory
    );

    if (validCateringCategories.length) {
      parsedParameters.catering_categories = validCateringCategories;
    } else {
      parsedParameters.catering_categories = ['other'];
    }
  }

  return `/start${stringify(
    {
      ...parsedParameters,
      leadSource: `${process.env.NEXT_PUBLIC_HOST}${
        router.asPath.split('?')[0]
      }`,
    },
    { addQueryPrefix: true }
  )}`;
};

export const parsedQueryParams = () => {
  if (typeof window === 'undefined') return {};

  return parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
};

export const capitalizeFirstLetter = function (string?: string | null) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const buildAddress = (addressComponents: {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}) => {
  return toPairs(addressComponents)
    .map((component) => {
      if (!component[1]) return '';
      switch (component[0]) {
        case 'street':
          return `${component[1]}, `;
        case 'postalCode':
          return `${component[1]} `;
        case 'city':
          return component[1];
        case 'country':
          return `, ${component[1]}`;
        default:
          return component[1];
      }
    })
    .join('');
};
