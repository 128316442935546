import { Client, gql } from 'urql';

import {
  createOpportunityCreationPayload,
  OpportunityCreationPayload,
} from 'shared/services/opportunityCreationPayload';

export const CREATE_OPPORTUNITY = gql`
  mutation($attributes: OpportunityAttributes!) {
    createOpportunity(attributes: $attributes) {
      success
      opportunity {
        id
        userId
      }
    }
  }
`;

export async function createOpportunity(
  payload: OpportunityCreationPayload,
  { client }: { client: Client }
) {
  const attributes = createOpportunityCreationPayload(payload);
  const { data: result } = await client
    .mutation(CREATE_OPPORTUNITY, { attributes })
    .toPromise();

  return result;
}

export const AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticateUser(credentials: { email: $email, password: $password }) {
      authToken
      userAccount {
        id
        email
        firstName
        lastName
        roles
        owner {
          ... on Customer {
            id
            companyName
            deliveryAddressStreetAndNumber
            deliveryAddressPostalCode
            deliveryAddressCity
            deliveryAddressCountry
            salesforceCustomer {
              billingAddress {
                street
                postalCode
                city
                country
              }
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export const authenticateUser = (
  email: string,
  password: string,
  { client }: { client: Client }
) => client.mutation(AUTHENTICATE_USER, { email, password }).toPromise();

export const FUNNEL_CHECKOUT = gql`
  mutation funnelCheckout($attributes: FunnelCheckoutAttributes!) {
    funnelCheckout(attributes: $attributes) {
      opportunity {
        id
        userId
      }
      customerAccount {
        uuid
      }
      success
      signInToken
    }
  }
`;

export type AccountAttributes = {
  billingAddressCity: string;
  billingAddressPostalCode: string;
  billingAddressStreetAndNumber: string;
  billingEmail?: string;
  billingName: string;
  hasRecurringNeed: boolean;
  name: string;
};

export interface FunnelCheckoutAttributes {
  accountAttributes: AccountAttributes;
  catererSlug?: string;
  opportunityAttributes: ReturnType<typeof createOpportunityCreationPayload>;
  userAttributes: unknown;
}

export const funnelCheckoutMutation = (
  attributes: FunnelCheckoutAttributes,
  { client }: { client: Client }
) => {
  return client.mutation(FUNNEL_CHECKOUT, { attributes }).toPromise();
};
