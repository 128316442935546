import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NewPill = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <Box position="relative" top="1px">
        <Typography fontSize={10} lineHeight={1} fontWeight={700}>
          {t('generic.new').toUpperCase()}
        </Typography>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 4px 8px;
  border-radius: 100px;
  background: #7d8f5c;
  color: #fff;
`;

export default NewPill;
