import React from 'react';
import { Box } from '@heycater/design-system';

function FormFieldGroup({ children }) {
  return (
    <Box mb={2} className="form-field-group">
      {children}
    </Box>
  );
}

export default FormFieldGroup;
