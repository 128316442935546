import React, { SVGProps } from 'react';

export default function TrustedShopsLogo({
  fill = '#fff',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 32 32"
      fill={fill}
      {...props}
    >
      <path
        id="etrusted-logo"
        d="M5.785,15.972A10.082,10.082,0,1,0,15.868,5.891,10.082,10.082,0,0,0,5.785,15.972ZM0,16.023a16,16,0,1,1,16,16A16,16,0,0,1,0,16.023Zm18.025-5.972a1.175,1.175,0,0,1,1.18,1.308c0,2.519-2.965,4.271-5.1,4.909.446-1.753,1.626-6.216,3.921-6.216Zm-3.538,13.2c2.74,0,4.781-1.977,6.248-4.144l-.8-.606c-1.052,1.275-2.485,3-4.271,3A2.127,2.127,0,0,1,13.626,19.1a11.714,11.714,0,0,1,.191-1.817l.445-.112c2.889-.719,7.97-1.984,7.97-5.4C22.232,9.766,20,9.1,18.343,9.1,13.4,9.1,9.9,13.75,9.9,18.5c0,2.932,1.689,4.749,4.59,4.749Z"
        transform="translate(0 -0.024)"
      />
    </svg>
  );
}
