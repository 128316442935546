import React, { useState } from 'react';

import FeedbackMessage from 'shared/components/FeedbackMessage';

const FeedbackMessageContext = React.createContext({});

function FeedbackMessageProvider({ children }) {
  const [variant, setVariant] = useState(null);
  const [message, setMessage] = useState(null);

  const resetMessage = () => {
    setMessage(null);
  };

  const setFeedbackMessage = (message, variant) => {
    setMessage(message);
    setVariant(variant);
  };

  return (
    <FeedbackMessageContext.Provider
      value={{
        resetMessage,
        setFeedbackMessage,
      }}
    >
      <FeedbackMessage
        message={message}
        variant={variant || 'success'}
        key={variant || 'success'}
      />
      {children}
    </FeedbackMessageContext.Provider>
  );
}

export { FeedbackMessageContext, FeedbackMessageProvider };
