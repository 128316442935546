import { Theme, useMediaQuery } from '@material-ui/core';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useBreakpointDown = (breakpoint: Breakpoint | number) => {
  const isUnderBreakpoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint)
  );
  return isUnderBreakpoint;
};

export const useBreakpointUp = (breakpoint: Breakpoint | number) => {
  const isOverBreakpoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint)
  );
  return isOverBreakpoint;
};
