export const cssVars = {
  topBarHeight: createCssVarHandlers('--top-bar-height'),
};

function getRoot() {
  return document.documentElement;
}

function createCssVarHandlers(variableName) {
  return {
    name: variableName,
    getValue() {
      return getComputedStyle(getRoot()).getPropertyValue(variableName);
    },
    setValue(value) {
      return getRoot().style.setProperty(variableName, value);
    },
  };
}
