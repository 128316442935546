import React from 'react';
import { TextField } from '@material-ui/core';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import FormFieldGroup from 'caterers/components/FormFieldGroup';

function FormField({
  field,
  form: { touched, errors },
  helperText,
  variant = 'filled',
  fullWidth = true,
  label = '',
  required = false,
  type = 'text',
  ...props
}) {
  const { t } = useTranslation();
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <FormFieldGroup>
      <TextField
        error={showError}
        helperText={(showError && t(fieldError)) || helperText}
        {...field}
        variant={variant}
        fullWidth={fullWidth}
        label={required ? `${label} *` : label}
        autoComplete="off"
        type={type}
        {...props}
      />
    </FormFieldGroup>
  );
}

export default FormField;
