import React from 'react';

export default function MenuIcon() {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="2" rx="1" fill="#374C19" />
      <rect x="11" y="7" width="15" height="2" rx="1" fill="#374C19" />
      <rect y="14" width="26" height="2" rx="1" fill="#374C19" />
    </svg>
  );
}
