import React, { useContext } from 'react';
import { Typography } from '@heycater/design-system';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CookieContext } from 'shared/components/CookieContextProvider.tsx';
import Locale from 'shared/components/Locale';
import { LINKS } from 'shared/constants';
import Logo from 'shared/svg/Logo';
import Container from 'system/components/Container';

const YEAR = new Date().getFullYear();

function Footer() {
  const { t } = useTranslation('static_pages');
  const { detailsOpen, setDetailsOpen } = useContext(CookieContext);

  return (
    <>
      <StyledFooter className="main-footer">
        <Container size="md">
          <FooterWrapper>
            <FooterColumn>
              <Logo alt="" fill="white" />
            </FooterColumn>
            <FooterColumn fontSize={12} color="white">
              <Locale />
            </FooterColumn>
            <FooterColumn as="nav">
              <h2 className="sr-only">Social Links</h2>
              <FooterSocialIconLink
                href="https://www.linkedin.com/company/heycater/"
                aria-label="Linkedin"
                target="_blank"
                rel="noopener"
              >
                <LinkedInIcon fontSize="small" />
              </FooterSocialIconLink>
              <FooterSocialIconLink
                href="https://www.facebook.com/heycater"
                aria-label="Facebook"
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon fontSize="small" />
              </FooterSocialIconLink>
              <FooterSocialIconLink
                href="https://twitter.com/HeyHeycater"
                aria-label="Twitter"
                target="_blank"
                rel="noopener"
              >
                <TwitterIcon fontSize="small" />
              </FooterSocialIconLink>
            </FooterColumn>
          </FooterWrapper>
          <FooterWrapper flexDirection="column" as="nav">
            <FooterColumn mobileColumn as="ul">
              <NavListItem>
                <Link href="/impressum" passHref>
                  <FooterLink>{t('common:navigation.imprint')}</FooterLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <Link href="/agb" passHref>
                  <FooterLink>{t('common:navigation.terms')}</FooterLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <Link href="/datenschutz" passHref>
                  <FooterLink>{t('common:navigation.privacy')}</FooterLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <StyledLink
                  as="button"
                  onClick={() => setDetailsOpen(!detailsOpen)}
                >
                  Cookies
                </StyledLink>
              </NavListItem>
              <NavListItem>
                <Link href={LINKS.career} rel="noopener" passHref>
                  <CareerLink target="_blank">
                    {t('common:navigation.career')}
                  </CareerLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <Link href="/about-us" passHref>
                  <FooterLink>About us</FooterLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <Link href="/hub" passHref>
                  <FooterLink>{t('common:navigation.blog')}</FooterLink>
                </Link>
              </NavListItem>
              <NavListItem>
                <Link href="/caterer" passHref>
                  <FooterLink>
                    🧑‍🍳 {t('common:navigation.partner')}
                  </FooterLink>
                </Link>
              </NavListItem>
            </FooterColumn>
          </FooterWrapper>
          <StyledTypography pt={2} fontSize={12}>
            {YEAR} Hey Group GmbH
          </StyledTypography>
        </Container>
      </StyledFooter>
    </>
  );
}

const NavListItem = styled.li`
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  align-items: start;
`;

const StyledLink = styled.a`
  background: inherit;
  border: inherit;
  line-height: 23px;
  height: inherit;
  color: inherit;
  margin: ${(props) => props.theme.spacing(2)}px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;

  text-decoration: none;
  ${(props) => props.theme.breakpoints.down('xs')} {
    margin: ${(props) => props.theme.spacing(0, 0, 1)};
  }
`;

const StyledFooter = styled.div`
  padding: ${(props) => props.theme.spacing(4, 0)};
  background: ${(props) => props.theme.palette.text.primary};
  color: white;
  border: none;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection};
  ${(props) => props.theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const FooterColumn = styled.div`
  padding: 0;
  display: flex;

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin: ${(props) => props.theme.spacing(1, 0)};
    display: flex;
    text-decoration: none;
    list-style: none;
    padding: 0;
    flex-direction: ${(props) => (props.mobileColumn ? 'column' : 'row')};
    align-items: center;
  }
`;

const FooterLink = styled.a`
  color: inherit;
  margin: ${(props) => props.theme.spacing(2)}px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  ${(props) => props.theme.breakpoints.down('xs')} {
    margin: ${(props) => props.theme.spacing(0, 0, 1)};
  }
`;

const FooterSocialIconLink = styled.a`
  color: inherit;
  cursor: pointer;
  margin: ${(props) => props.theme.spacing(0, 1.75)};
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
  text-align: center;
`;

const CareerLink = styled(FooterLink)`
  color: #9cba6c;
  text-decoration: none;
`;

export default Footer;
