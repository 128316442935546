import { Box } from '@heycater/design-system';
import styled from 'styled-components';

const sizes = {
  xs: 690,
  xsm: 860,
  sm: 930,
  md: 1250,
  lg: 1890,
} as const;

export type ContainerSize = keyof typeof sizes;

export interface ContainerProps {
  size?: ContainerSize | number;
  disableGutters?: boolean;
  padding?: number;
}

const StyledContainer = styled(Box)<ContainerProps>`
  --padding: ${({ theme, disableGutters, padding }) =>
    disableGutters ? 0 : theme.spacing(padding ?? 2)}px;

  --contentMaxWidth: ${({ size }) =>
    typeof size === 'number' ? size : sizes[size || 'md']}px;

  --maxWidthIncludingPadding: calc(
    var(--contentMaxWidth) + (2 * var(--padding))
  );

  margin-left: auto;
  margin-right: auto;
  padding-left: var(--padding);
  padding-right: var(--padding);

  width: clamp(100px, 100%, var(--maxWidthIncludingPadding));
`;

const Container = StyledContainer;

export default Container;
