import Cookies from 'js-cookie';

import { Answers } from 'qualification/context/model';
import { CateringCategoryValue } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { CustomerTierValue } from 'qualification/schema/questions/customerTierQuestion';
import { OccasionValue } from 'qualification/schema/questions/occasion';
import { platformToSalesforceCateringCategoryMapping } from 'qualification/services/webToLeadData';

/**
 * Values, that the `createOpportunity`'s `occasion` parameter accepts
 */
type OpportunityOccasion =
  | 'Breakfast'
  | 'Meeting'
  | 'Team Lunch'
  | 'Shooting / Dreh'
  | 'Dinner'
  | 'BBQ'
  | 'Firmenfeier / Event'
  | 'Messe / Ausstellung'
  | 'Virtual Event'
  | 'Marketplace'
  | 'Sonstiges'
  | 'Team Überraschung'
  | 'Sommerfeier'
  | 'Weihnachtsfeier';

const occasionToEventMapping: Record<OccasionValue, OpportunityOccasion> = {
  meeting: 'Meeting',
  team_event: 'Firmenfeier / Event',
  company_event: 'Firmenfeier / Event',
  summer_party: 'Sommerfeier',
  christmas: 'Weihnachtsfeier',
  conference: 'Meeting',
  shooting: 'Shooting / Dreh',
  other: 'Sonstiges',
};

const CLOUD_GROUPS_SLUGS = {
  CATERING_CATEGORIES: 'catering-categories',
};

export type OpportunityCreationPayload = Required<Answers> & {
  analyticsClientId: string;
  billingAddressStreetAndNumber: string;
  billingAddressPostalCode: string;
  billingAddressCity: string;
  billingCountry: string;
  catering_categories: CateringCategoryValue[];
  caterer_minimum_order_value: number;
  conversion_url: string;
  comment?: string;
  customerTier?: CustomerTierValue;
  lead_language?: string;
  deliveryAddressStreetAndNumber: string;
  deliveryAddressPostalCode: string;
  deliveryAddressCity: string;
  deliveryCountry: string;
  drinks?: boolean;
  email: string;
  company: string;
  phone: string;
  event_date: Date;
  equipment?: boolean;
  occasion?: OccasionValue;
  first_name: string;
  landingPageSource?: string;
  isPredefinedOffer?: boolean;
  last_name: string;
  min_order_number: number;
  number_of_vegetarians: number;
  number_of_vegans: number;
  number_of_carnivore: number;
  price_per_person: number;
  predefined_catering_offer_title?: string;
  reorder_offer_id: string | string[] | undefined | null;
  service?: boolean;
  catererSlug?: string;
  leadSource: string;
  commentByHeycaterForCaterer?: string;
  inspiredByMenuId?: string;
  degree_of_satiation?: string;
  caterer_name?: string;
};

const prepareGroupTagValues = (
  groupSlug: string,
  selectedValues: (string | undefined)[]
) => {
  return selectedValues.map((value) => {
    return {
      tagSlug: value,
      groupSlug: groupSlug,
    };
  });
};

const cloudGroupTagValuesPayload = (payload: OpportunityCreationPayload) => {
  const { catering_categories } = payload;
  const selectedCateringCategories = catering_categories
    .filter((value) => value !== 'other')
    .map(
      (cateringCategory) =>
        platformToSalesforceCateringCategoryMapping[cateringCategory]
    );
  return prepareGroupTagValues(
    CLOUD_GROUPS_SLUGS.CATERING_CATEGORIES,
    selectedCateringCategories
  );
};

export function createOpportunityCreationPayload(
  payload: OpportunityCreationPayload
) {
  const {
    billingAddressStreetAndNumber = '',
    billingAddressPostalCode = '',
    billingAddressCity = '',
    customer_tier,
    billingCountry = '',
    catering_categories,
    landingPageSource,
    caterer_minimum_order_value,
    caterer_name = '',
    inspiredByMenuId,
    conversion_url = '',
    occasion,
    comment,
    degree_of_satiation,
    deliveryAddressStreetAndNumber = '',
    deliveryAddressPostalCode = '',
    deliveryAddressCity = '',
    deliveryCountry = '',
    drinks = false,
    event_date,
    equipment = false,
    first_name,
    isPredefinedOffer = false,
    last_name,
    min_order_number,
    number_of_vegetarians,
    number_of_vegans,
    number_of_carnivore,
    price_per_person,
    catererSlug,
    predefined_catering_offer_title = '',
    reorder_offer_id = '',
    service = false,
    leadSource = '',
    is_multi_day,
    analyticsClientId,
  } = payload;

  return {
    analyticsClientId: analyticsClientId,
    cateringCategories: catering_categories.map((cateringCategory) =>
      cateringCategory === 'other'
        ? undefined
        : platformToSalesforceCateringCategoryMapping[cateringCategory]
    ),
    occasion: occasion ? occasionToEventMapping[occasion] : undefined,
    overallBudget: caterer_minimum_order_value,
    budgetPerPerson: price_per_person,
    customerTier: customer_tier,
    numberOfPeople: min_order_number,
    vegetarianNumber: number_of_vegetarians,
    veganNumber: number_of_vegans,
    meatNumber: number_of_carnivore,
    catererSlug,
    isMultiday: is_multi_day,
    inspiredByMenuId,
    landingPageSource,
    internalComment: `
    ${
      predefined_catering_offer_title &&
      `-- PREDEFINED OFFER---
      Pre-defined offer selected: Menu: ${predefined_catering_offer_title}
      Caterer: ${caterer_name}
    `
    }
    ${
      reorder_offer_id &&
      `-- RE-ORDER ---
      Re-Order offer selected: ${reorder_offer_id}
      Link: https://heycater.lightning.force.com/lightning/r/Catering_Offer__c/${reorder_offer_id}/view
    `
    }`,
    commentByHeycaterForCaterer: comment,
    predefinedOffers: isPredefinedOffer,
    contactPersonNote: `${first_name} ${last_name}`,
    billingAddressStreetAndNumber,
    billingAddressPostalCode,
    billingAddressCity,
    billingCountry,
    leadSource,
    deliveryAddressStreetAndNumber,
    deliveryAddressPostalCode,
    deliveryAddressCity,
    deliveryCountry,
    drinks,
    furniture: equipment,
    staff: service,
    locationCity: deliveryAddressCity,
    conversionUrl: conversion_url,
    entranceUrl: Cookies.get('entrance_url'),
    referrerUrl: Cookies.get('referrer_url'),
    degreeOfSatiation: degree_of_satiation,
    eventsAttributes: [
      {
        startsAt: event_date,
      },
    ],
    cloudGroupTagValuesAttributes: cloudGroupTagValuesPayload(payload),
  };
}
