import React, { ReactNode } from 'react';
import { Box } from '@heycater/design-system';
import PropTypes from 'prop-types';

import Footer from 'shared/layouts/Footer';
import Navigation from 'shared/layouts/Navigation';
import { Ratings } from 'shared/services/trustedShops/model';

interface MainLayoutProps {
  children: ReactNode;
  ratings: Ratings;
  hideNavigationLinks?: boolean;
  hideNavbar?: boolean;
  renderNavbarCta?: ReactNode;
}

function MainLayout({
  children,
  hideNavigationLinks = false,
  hideNavbar = false,
  renderNavbarCta,
  ratings,
}: MainLayoutProps) {
  if (hideNavbar) {
    return <PageLayout main={children} footer={<Footer />} />;
  }

  return (
    <PageLayout
      header={
        <Navigation
          ratings={ratings}
          hideNavigationLinks={hideNavigationLinks}
          renderCTA={renderNavbarCta}
        />
      }
      main={children}
      footer={<Footer />}
    />
  );
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

interface PageLayoutProps {
  header?: ReactNode;
  main: ReactNode;
  footer?: ReactNode;
  root?: ReactNode;
}

export const PageLayout = ({ header, main, footer, root }: PageLayoutProps) => {
  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box position="sticky" top="0" zIndex="100">
          {header}
        </Box>
        <Box
          as="main"
          display="flex"
          overflow="clip"
          flexDirection="column"
          flexGrow="1"
        >
          {main}
        </Box>
        <Box as="footer" mt="auto">
          {footer}
        </Box>
      </Box>
      {root}
    </>
  );
};

export default MainLayout;
