import React from 'react';

export default function TrustedShopsStars() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.181 18.982">
      <g id="ic-review_rating" transform="translate(10824 20794)">
        <path
          id="star-filled"
          d="M9.489,0,7.249,7.248,0,7.251l5.865,4.48L3.627,18.982,9.488,14.5l5.868,4.48-2.239-7.251,5.865-4.48-7.249,0Z"
          transform="translate(-10731.801 -20794)"
          fill="#ffdc0f"
          fillRule="evenodd"
        />
        <path
          id="star-filled-2"
          data-name="star-filled"
          d="M9.489,0,7.249,7.248,0,7.251l5.865,4.48L3.627,18.982,9.488,14.5l5.868,4.48-2.239-7.251,5.865-4.48-7.249,0Z"
          transform="translate(-10777.9 -20794)"
          fill="#ffdc0f"
          fillRule="evenodd"
        />
        <path
          id="star-filled-3"
          data-name="star-filled"
          d="M9.489,0,7.249,7.248,0,7.251l5.865,4.48L3.627,18.982,9.488,14.5l5.868,4.48-2.239-7.251,5.865-4.48-7.249,0Z"
          transform="translate(-10754.851 -20794)"
          fill="#ffdc0f"
          fillRule="evenodd"
        />
        <path
          id="star-filled-4"
          data-name="star-filled"
          d="M9.489,0,7.249,7.248,0,7.251l5.865,4.48L3.627,18.982,9.488,14.5l5.868,4.48-2.239-7.251,5.865-4.48-7.249,0Z"
          transform="translate(-10800.95 -20794)"
          fill="#ffdc0f"
          fillRule="evenodd"
        />
        <path
          id="star-filled-5"
          data-name="star-filled"
          d="M9.489,0,7.249,7.248,0,7.251l5.865,4.48L3.627,18.982,9.488,14.5l5.868,4.48-2.239-7.251,5.865-4.48-7.249,0Z"
          transform="translate(-10824 -20794)"
          fill="#ffdc0f"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
