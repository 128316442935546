import { format } from 'date-fns';
import Cookies from 'js-cookie';

import { CateringCategoryValue } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { SALESFORCE_LEAD_RECORD_TYPES_MAPPING } from 'shared/constants';
import { getIdentity } from 'shared/helpers/analytics';
import { findCityNameByLocale } from 'shared/helpers/cities';

export const webToLeadData = ({
  caterer_minimum_order_value,
  caterer_name = '',
  catering_categories,
  city,
  comment,
  company,
  conversion_url,
  degree_of_satiation,
  deliveryAddressStreetAndNumber = '',
  deliveryAddressCity = '',
  deliveryAddressPostalCode = '',
  deliveryAddressCountry = '',
  drinks,
  email,
  equipment,
  event_date,
  first_name,
  isPredefinedOffer,
  last_name,
  lead_type,
  min_order_number,
  number_of_vegans,
  number_of_vegetarians,
  number_of_carnivore,
  phone,
  price_per_person,
  predefined_catering_offer_title = '',
  service,
}: any) => {
  const mappedCateringCategories = catering_categories.map(
    (cateringCategory: CateringCategoryValue) =>
      platformToSalesforceCateringCategoryMapping[cateringCategory]
  );

  const mappedEventType = catering_categories.map(
    (eventType: 'summer_party' | 'christmas') =>
      platformToSalesforceEventTypeMapping[eventType]
  );

  const eventDate = (() => {
    if (!event_date?.date) return undefined;

    return format(new Date(event_date.date), 'dd.MM.yyyy');
  })();

  const recurringNeed = (() => {
    return event_date?.recurring === true;
  })();

  const privateLead = (() => {
    return lead_type === 'private';
  })();

  return {
    [salesforceFieldMapping.oid]:
      process.env.NEXT_PUBLIC_SALESFORCE_WEB_TO_LEAD_OID,
    [salesforceFieldMapping.entrance_url]: Cookies.get('entrance_url'),
    [salesforceFieldMapping.referrer_url]: Cookies.get('referrer_url'),
    [salesforceFieldMapping.lead_source]: 'heycater.com Web2Lead form',
    [salesforceFieldMapping.identity]: getIdentity(),
    [salesforceFieldMapping.city]:
      findCityNameByLocale(city, 'de') || deliveryAddressCity,
    [salesforceFieldMapping.conversion_url]: conversion_url,
    [salesforceFieldMapping.first_name]: first_name,
    [salesforceFieldMapping.last_name]: last_name,
    [salesforceFieldMapping.company]: company,
    [salesforceFieldMapping.email]: email,
    [salesforceFieldMapping.phone]: phone,
    [salesforceFieldMapping.comment]: `
-- CUSTOMER COMMENT --
${comment ?? ''}
${
  predefined_catering_offer_title &&
  `-- PREDEFINED OFFER---
  Pre-defined offer selected: Menu: ${predefined_catering_offer_title}
  Caterer: ${caterer_name}
`
}`,
    [salesforceFieldMapping.catering_categories]: mappedCateringCategories,
    [salesforceFieldMapping.deliveryAddressStreetAndNumber]: deliveryAddressStreetAndNumber,
    [salesforceFieldMapping.deliveryAddressCity]: deliveryAddressCity,
    [salesforceFieldMapping.deliveryAddressPostalCode]: deliveryAddressPostalCode,
    [salesforceFieldMapping.deliveryAddressCountry]: deliveryAddressCountry,
    [salesforceFieldMapping.event_type]: mappedEventType,
    [salesforceFieldMapping.event_date]: eventDate,
    [salesforceFieldMapping.pax]: min_order_number,
    [salesforceFieldMapping.number_of_vegans]: number_of_vegans,
    [salesforceFieldMapping.number_of_vegetarians]: number_of_vegetarians,
    [salesforceFieldMapping.number_of_carnivore]: number_of_carnivore,
    [salesforceFieldMapping.total_budget]: caterer_minimum_order_value,
    [salesforceFieldMapping.price_per_person]: price_per_person,
    [salesforceFieldMapping.recurring_need]: recurringNeed,
    [salesforceFieldMapping.private_lead]: privateLead,
    [salesforceFieldMapping.record_type]:
      SALESFORCE_LEAD_RECORD_TYPES_MAPPING['Customer Lead'],
    [salesforceFieldMapping.predefined_offer]: predefined_catering_offer_title
      ? '1'
      : '0',
    [salesforceFieldMapping.degree_of_satiation]: degree_of_satiation,
    [salesforceFieldMapping.predefined_offer]: isPredefinedOffer,
    [salesforceFieldMapping.equipment]: equipment,
    [salesforceFieldMapping.drinks]: drinks,
    [salesforceFieldMapping.service]: service,
  };
};

const salesforceFieldMapping = {
  catering_categories: '00N1i000002IQkd',
  city: '00N5800000CZO0T',
  company: 'company',
  comment: '00N58000007Q0vz',
  conversion_url: '00N1i000002WWRT',
  degree_of_satiation: 'Level_of_Satiation__c',
  deliveryAddressStreetAndNumber: '00N1i000000ldlY',
  deliveryAddressCity: '00N1i000000ldli',
  deliveryAddressPostalCode: '00N1i000002H93Y',
  deliveryAddressCountry: '00N1i000002HrsJ',
  drinks: 'Drinks__c',
  email: 'email',
  entrance_url: '00N1i000001A0eY',
  equipment: 'Furnishing__c',
  event_type: '00N1i000002WRXM',
  event_date: '00N5800000E2R9g',
  first_name: 'first_name',
  identity: '00N1i000002WT1c',
  last_name: 'last_name',
  lead_language: '00N1i000002Nvv0',
  lead_source: 'lead_source',
  number_of_vegans: 'Anzahl_Vegan__c',
  number_of_vegetarians: 'Anzahl_Vegetarisch__c',
  number_of_carnivore: 'Anzahl_Fleisch__c',
  oid: 'oid',
  pax: '00N58000005JWfk',
  price_per_person: '00N58000009Ikbm',
  phone: 'phone',
  predefined_offer: '00N9Y000000OMsR',
  private_lead: '00N1i000002Ndz8',
  referrer_url: '00N1i000001A0eT',
  total_budget: '00N58000009IkXa',
  record_type: 'recordType',
  recurring_need: '00N9Y000000NseP',
  service: 'Staff__c',
};

export const platformToSalesforceCateringCategoryMapping: Record<
  | CateringCategoryValue
  | 'event'
  | 'specials'
  | 'home_office'
  | 'easter'
  | 'halloween',
  string | undefined
> = {
  breakfast: 'breakfast',
  business_lunch: 'business-lunch',
  drinks: 'drinks',
  fingerfood: 'fingerfood',
  cakes_and_sweets: 'snacks-and-dessert',
  street_food: 'street-food',
  event: 'event',
  fine_dining: 'fine-dining',
  buffet: 'buffet',
  specials: 'specials',
  home_office: 'home-office',
  bbq: 'bbq',
  easter: 'easter',
  halloween: 'halloween',
  christmas: 'christmas',
  other: 'other',
};

const platformToSalesforceEventTypeMapping = {
  summer_party: 'Sommerfeier',
  christmas: 'Weihnachtsfeier',
};
