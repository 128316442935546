import { Ratings } from 'shared/services/trustedShops/model';

export const getRating = (ratings: Ratings) => ratings['overall'].rating;
export const getRatingCount = (ratings: Ratings) => ratings['overall'].count;

export const formatRating = (rating: number) =>
  (Math.round(rating * 10) / 10).toFixed(1);
export const formatRatingCount = (ratingCount: number) => {
  if (ratingCount < 10) {
    return ratingCount;
  }
  if (ratingCount < 100) {
    return 10 * Math.floor(ratingCount / 10) + '+';
  }
  return 100 * Math.floor(ratingCount / 100) + '+';
};
