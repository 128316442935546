import { useCallback } from 'react';
import { useClient } from 'urql';

import { authenticateUser as authenticateUserMutation } from 'lib/graphql/mutations';
import useCurrentUser from 'qualification/hooks/useCurrentUser';

export default function useAuthentication() {
  const { refetch: refetchCurrentUser } = useCurrentUser();
  const client = useClient();
  const login = useCallback(
    async (variables: { email: string; password: string }) => {
      const {
        data: {
          authenticateUser: { authToken, userAccount },
        },
      } = await authenticateUserMutation(variables.email, variables.password, {
        client,
      });

      const onSubmitResponse = async () => {
        localStorage.setItem('token', authToken);
        refetchCurrentUser({ requestPolicy: 'network-only' });

        return {
          userAccount,
        };
      };

      await onSubmitResponse();

      return { authToken, userAccount };
    },
    [refetchCurrentUser]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    refetchCurrentUser({ requestPolicy: 'network-only' });
  }, []);

  return {
    login,
    logout,
  };
}
