import { useEffect, useRef, useState } from 'react';

export function useElementSize() {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const prevSizeRef = useRef({ width: 0, height: 0 });
  const ref = useRef(null);
  function debounce(fn, delay) {
    let timeoutId;
    return function debouncedFn(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  useEffect(() => {
    const element = ref.current;

    const handleResize = debounce((entries) => {
      const { width: newWidth, height: newHeight } = entries[0].contentRect;

      if (
        prevSizeRef.current.width !== newWidth ||
        prevSizeRef.current.height !== newHeight
      ) {
        prevSizeRef.current = { width: newWidth, height: newHeight };
        setSize({ width: newWidth, height: newHeight });
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [ref]);

  return { ref, ...size };
}
