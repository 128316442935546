// Source: https://help.mouseflow.com/en/articles/4274450-forms-with-no-successful-submits
const FORM_SUBMIT_ATTEMPT = 'formSubmitAttempt';
const FORM_SUBMIT_SUCCESS = 'formSubmitSuccess';
const FORM_SUBMIT_FAILURE = 'formSubmitFailure';

function mouseflowNotify(type, htmlId) {
  if (typeof htmlId !== 'string') {
    return;
  }

  if (!window._mfq) {
    window._mfq = [];
  }

  window._mfq.push([type, `#${htmlId}`]);
}

export const Mouseflow = {
  notify: {
    formSubmitAttempt(formId) {
      mouseflowNotify(FORM_SUBMIT_ATTEMPT, formId);
    },
    formSubmitSuccess(formId) {
      mouseflowNotify(FORM_SUBMIT_SUCCESS, formId);
    },
    formSubmitFailure(formId) {
      mouseflowNotify(FORM_SUBMIT_FAILURE, formId);
    },
  },
};
