import { v4 as uuidv4 } from 'uuid';

import { doesLocalStorageExist } from 'shared/helpers/localStorageExists';

const STORAGE_KEY = '_heycater_identity';

const USER_IDENTITY_VALUE = uuidv4();

export function getIdentity() {
  if (!doesLocalStorageExist()) {
    return USER_IDENTITY_VALUE;
  }

  if (!localStorage.getItem(STORAGE_KEY)) {
    localStorage.setItem(STORAGE_KEY, USER_IDENTITY_VALUE);
  }

  return localStorage.getItem(STORAGE_KEY);
}

export function identifyUser(identity) {
  window.gtag &&
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_TAG_ID, {
      user_id: identity,
    });
  window.analytics && window.analytics.identify(identity);
}

export function pushDataLayer(uuid) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    userId: uuid,
  });
}

export function trackConversion(properties = {}) {
  window.analytics && window.analytics.track('conversion', properties);
}
