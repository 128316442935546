import React from 'react';

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1197 244.92"
      width="150"
      {...props}
    >
      <path
        d="M512.39,180.42v-47c0-8.61-6.62-16-15.22-16.4-10.51-.47-17.94,7.63-17.94,17.83v45.56H454.72V36.25h24.51v69.2h.39c6.56-7.89,16.5-10.86,26.85-10.86,19.49,0,30.43,10.86,30.43,32.57v53.26Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M583.5,145.82c2,9.91,9.9,17.83,19.82,17.83s15.85-2,23.18-9.9l18.63,9.71c-10.1,14.06-22,19-41.81,19-27.75,0-45.58-18.82-45.58-44.58s17.83-44.6,45.58-44.6c26,0,44.59,19.83,44.59,42.61v9.92Zm20.36-31.72C592,114.1,586,122,584,130h39.64C621.69,122,615.74,114.1,603.86,114.1Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M696.22,217.9H670.41l19.86-47.64L658.5,96.8h26L702,140.05h.38l17.5-43.25h26Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M801.49,183.05c-27.75,0-45.58-18.82-45.58-44.58s17.84-44.6,45.58-44.6c15.86,0,25.76,5,34.67,14.88l-12.87,15.84c-5.94-5.94-10.9-9.91-21.8-9.91-11.69,0-19.82,9.91-19.82,23.79s8.92,23.77,19.82,23.77c9.91,0,15.86-4,21.8-11.89l15.86,15.86C827.25,178.1,817.35,183.05,801.49,183.05Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M918.94,180.42v-8.65h-.4c-6.56,8-16.53,10.95-28.49,10.95-19.93,0-37.87-16.93-37.87-44.83S870.12,93,890.05,93c12,0,21.93,3,28.49,11h.4V96.8h24.51v83.62Zm-21.11-63.75c-9.91,0-19.81,7.92-19.81,21.8s9.9,21.8,19.81,21.8c11.89,0,20.81-7.93,20.81-21.8S909.72,116.67,897.83,116.67Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M1000.5,183.11c-13.93,0-23.89-7-23.89-24.88v-39.8H962.89l3-21.63h10.77V69.28l24.51-3.93V96.8h17.3v21.63h-17.3v31.44c0,9.84,9.17,11.79,17.3,3.94v22.33C1014.44,180.12,1006.68,183.11,1000.5,183.11Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M1063.59,145.82c2,9.91,9.91,17.83,19.82,17.83s15.86-2,23.19-9.9l18.62,9.71c-10.11,14.06-22,19-41.81,19-27.75,0-45.59-18.82-45.59-44.58s17.84-44.6,45.59-44.6c25.95,0,44.58,19.83,44.58,42.61v9.92Zm20-31.72c-11.89,0-17.83,7.94-19.82,15.86h39.64C1101.41,122,1095.47,114.1,1083.58,114.1Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M1168.36,136.15v44.27h-24.51V96.8h24.51v10.09h.4c6.7-10.09,12.79-13.12,27-13.12V117C1177.48,115,1168.36,123,1168.36,136.15Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M1213.05,140.05V37.69h26V140.05Zm0,40.37V153h26v27.39Z"
        transform="translate(-42 0.47)"
      />
      <path
        className="cls-1"
        d="M137.12,236.78"
        transform="translate(-42 0.47)"
      />
      <path
        d="M217.92,38.07S224,25,244.68,24.74L244.3,7.06S216,6,202.38,29.36Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M175.26,173.1c1.11-36.37-28.88-48.59-28.88-48.59,24-43.6,24-89.54,24-89.54-1.93-41.8-42.15-35-42.15-35l1.86,17.48c24.79-3.32,22.77,19.26,22.77,19.26,2.76,27.39-24.07,84.22-24.07,84.22-34.8,0-55.72,35.91-55.72,35.91C55,190.13,60.87,214.2,76,213.27s29.8-20.73,29.8-20.73c14.26-16.29,32.95-51.65,32.95-51.65,19.07,7.59,19.25,32.59,19.25,32.59-1.95,39.54-27.39,57.94-27.39,57.94l11.84,13C178.4,212.16,175.26,173.1,175.26,173.1ZM79.18,194s5.51-44.62,39.41-53.14C118.59,140.89,91.41,188.64,79.18,194Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M42,87.14s21.63,6.74,32.59,15.25L85.32,88.32S65.7,73.7,44.6,69.81Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M306.47,120.9C305.56,96.83,273,97.38,273,97.38c-23.13.93-33.77,7.41-33.77,7.41-9.35-16.47,6.75-37,6.75-37L232.44,56.29C208.37,86.08,223.55,115,223.55,115c-31.1,26.08-32.94,56.09-15,83.67S263,227.3,263,227.3l.19-17.38c-27.21-1.84-48.12-23-48.3-45S235,128.65,235,128.65c16.29,12.77,40,14.26,40,14.26C310,143.11,306.47,120.9,306.47,120.9Zm-18.84,2.42c-2.79,3-28.69,1.3-35-4.63,0,0,20-8.32,35,0,0,0,2.77,1.67,0,4.63Z"
        transform="translate(-42 0.47)"
      />
      <path
        d="M316.17,190.18s4.17-9.57,20.41-21.52l-10.2-14.58s-18.95,13.47-26.3,27.63Z"
        transform="translate(-42 0.47)"
      />
    </svg>
  );
}

export default Logo;
